const Agents = () =>{

  const agentsInfo=[
    {
      name:'Jobin Johnson',
      image:'img/team/a10.png',
    },
    {
      name:'Priyan Jacob',
      image:'img/team/a9.png',
    },
    {
      name:'Alan James',
      image:'img/team/a3.png',
    },
    {
      name:'Allen Mannil',
      image:'img/team/a8.png',
    },
    {
      name:'Brittney Waiters',
      image:'img/team/a2.png',
    },
    {
      name:'Delwin Panjikaran',
      image:'img/team/a5.png',
    },
    {
      name:'Ebin Kurian',
      image:'img/team/a1.png',
    },
    {
      name:'Elezabeth Zachariah',
      image:'img/team/l1.png',
    },
    {
      name:'James Mathew',
      image:'img/team/a4.png',
    },
    {
      name:'Joyce Paul',
      image:'img/team/a6.png',
    },
    {
      name:'Resmi Mathew',
      image:'img/team/l2.png',
    },  
    {
      name:'Yolanda McKinney',
      image:'img/team/a7.png',
    },   
  ]
return(
    <section>
     <section class="intro-single">
        <div class="container">
          <div class="row">
            <div class="col-md-12 col-lg-8">
              <div class="title-single-box">
                <h1 class="title-single">Our Team</h1>
              </div>
            </div>
            <div class="col-md-12 col-lg-4">
              <nav aria-label="breadcrumb" class="breadcrumb-box d-flex justify-content-lg-end">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    Our Team
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </section>
    <div className="container">
      

      <div className="row">
      {agentsInfo.map((item,index)=>{
        return(
          <div className="col-md-4" key={index}>
          <div className="card-box-d">
            <div className="card-img-d">
              <img src={item.image} alt="" className="img-d img-fluid" style={{borderRadius:'10px',overflow:'hidden'}}/>
              <h3 className="text-center mt-3 mb-5" style={{fontWeight:600}}>{item?.name}</h3>
            </div>
          </div>
          
        </div>
        )
      })}

      </div>
    </div>
  </section>
)
}

export default Agents;