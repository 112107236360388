import React, { useState, useEffect }  from "react";
import Slider from "react-slick";
import "./slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import { useMediaQuery } from "react-responsive";
import "./slick-theme.css";
// import { getAdvs } from "../functions/adv"

const Banner = ({images}) => {
//   const [category, setCategory] = useState({});
//   const [advs, setAdvs] = useState([]);
//   const [loading, setLoading] = useState(false);
 
//   useEffect(() => {
//     loadCategories();
//   }, []);


// const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
// const isIpad = useMediaQuery({ query: "(max-width: 850px)" });
//   const classes = UseStyles();
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows:true,
    autoplay:true
  };
  return (
      <div style={{backgroundColor:'white'}}>
    <Slider {...settings} autoplaySpeed={3000}>
     {/* {advs?.map((item)=>{
       return  <img src={item?.images[0]?.url} style={{width:'100%',height:'100%'}}/>
     })} */}
     {images?.map((item,index)=>{
       return(
        <img src={item} alt="" key={index}/>
       )
     })}
     {/* <img src="img/slides/slide1.png" alt="" />
      <img src="img/slides/slide2.png" alt="" />
     <img src="img/slides/slide3.png" alt="" />
      <img src="img/slides/slide4.png" alt="" />
      <img src="img/slides/slide5.png" alt="" />
      <img src="img/slides/slide6.png" alt="" /> */}
    </Slider>
    </div>
  );
};

export default Banner;
