import Banner from "../component/Banner"

const AboutUs = () =>{
  const images=["img/slides/slide1.png","img/slides/slide2.png","img/slides/slide3.png","img/slides/slide4.png","img/slides/slide5.png","img/slides/slide6.png"]
    return(
        <div>
        <section class="intro-single">
        <div class="container">
          <div class="row">
            <div class="col-md-12 col-lg-8">
              <div class="title-single-box">
                <h1 class="title-single">About Us</h1>
              </div>
            </div>
            <div class="col-md-12 col-lg-4">
              <nav aria-label="breadcrumb" class="breadcrumb-box d-flex justify-content-lg-end">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    About
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </section>

      <section class="section-about">
        <div class="container">
          <div class="row">
            <div class="col-sm-12">
              <div class="about-img-box">
                <img src="img/slide-about-1.png" alt="" class="img-fluid" style={{borderRadius:'10px'}}/>
              </div>
              <div class="sinse-box">
                <h3 class="sinse-title">JP Group
                  <span></span>
                  <br/> Since 2020</h3>
                <p class="color-text-b">Buy | Sell | Invest</p>
              </div>
            </div>
            <div class="col-md-12 section-t8">
              <div class="row">
                <div class="col-md-6 col-lg-5">
                <Banner images={images}/>
                </div>
                <div class="col-lg-1  d-none d-lg-block">
                  <div class="title-vertical d-flex justify-content-start">
                   
                  </div>
                </div>
                <div class="col-md-6 col-lg-5 section-md-t3">
                  <div class="title-box-d">
                    <h3 class="title-d">JP Group
                     </h3>
                  </div>
                 
                  <p class="color-text-b">
                  JP Group realty was created by experienced
marketers and property experts who
recognized a harsh truth. As a trusted
advisor, we are committed to delivering the
highest level of service and marketing
through sharing their experience and
expertise.
                  </p>
                 
                  <p class="color-text-b pt-3">
                  Starting in 2020, the JP group is one of the
fastest-growing realtor teams in the city. Our
passion and vision for real estate have led to
a powerful and respectful team of agents.
Our ability to motivate and bring changes to
what a traditional agency, has brought
insurmountable growth to this team.
In 2021, the team has closed over 150+
houses. 
                  </p>

                
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      </div>
    )
}

export default AboutUs;