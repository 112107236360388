import { Link } from "react-router-dom";

const Footer = () => {
  const links=[
    {
        path:'/',
        name:'Home'

    },
    {
        path:'/agents',
        name:'Our Team'

    },
    {
        path:'/aboutus',
        name:'Company'

    },
    {
        path:'https://www.har.com/jobin-priyan-group/team_844',
        name:'Listings'

    },
    {
        path:'/contactus',
        name:'Contact'

    },
]
  return (
    <div>
      <section className="section-footer">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-4">
              <div className="widget-a">
                <div className="w-header-a">
                  <h3 className="w-title-a text-brand">JP GROUP</h3>
                </div>
                <div className="w-body-a">
                  <p className="w-text-a color-text-a">
                  JP Group realty was created by experienced
marketers and property experts who
recognized a harsh truth. As a trusted
advisor, we are committed to delivering the
highest level of service and marketing
through sharing their experience and
expertise.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-4 section-md-t3">
              <div className="widget-a">
                <div className="w-header-a">
                  <h3 className="w-title-a text-brand">Reach Us</h3>
                </div>
                <div className="w-footer-a">
                  <ul className="list-unstyled">
                    <li className="color-a">
                      <span className="color-text-a">
                        7070 Knights Ct, Unit 402, Missouri City, Texas 77459{" "}
                      </span>{" "}
                    </li>
                    <li className="color-a">
                      <span className="color-text-a">
                        Email: jpgrouprealty@gmail.com
                      </span>{" "}
                    </li>
                    <li className="color-a">
                      <span className="color-text-a">
                        Phone: (832) 573-6443, (832) 391-5000{" "}
                      </span>{" "}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-4 section-md-t3">
              <div className="widget-a">
                <div className="w-header-a">
                  <h3 className="w-title-a text-brand">Quick Links</h3>
                </div>
                <div className="w-body-a">
                  <ul className="list-unstyled">

                  {links?.map((item,index)=>{
                    if(item?.name==="Listings"){
               return <li className="item-list-a" key={index}>
                      <i className="fa fa-angle-right"></i>{" "}<a href={item?.path} className="quick-link-color">{item?.name}  </a>
                      </li>
             } else {
                    return(
                      <li className="item-list-a" key={index}>
                      <i className="fa fa-angle-right"></i>{" "}
                      <Link to={item?.path} key={index} className="quick-link-color">
                      {item?.name}
                      </Link>
                    </li>
                    )}
                  })}
                  
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <footer>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="socials-a">
                <ul className="list-inline">
                  <li className="list-inline-item">
                    <a href="https://www.facebook.com/JPGroup.Stride">
                      <i
                        className="fa fa-facebook quick-link-color"
                        aria-hidden="true"
                      ></i>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a href="#">
                      <i
                        className="fa fa-twitter quick-link-color"
                        aria-hidden="true"
                      ></i>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a href="#">
                      <i
                        className="fa fa-instagram quick-link-color"
                        aria-hidden="true"
                      ></i>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a href="#">
                      <i
                        className="fa fa-pinterest-p quick-link-color"
                        aria-hidden="true"
                      ></i>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a href="#">
                      <i
                        className="fa fa-dribbble quick-link-color"
                        aria-hidden="true"
                      ></i>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="copyright-footer">
                <p className="copyright color-text-a">
                  &copy; Copyright JP Group All Rights Reserved.
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
