import { FaBath, FaBed,FaCar } from 'react-icons/fa';
import { Link, withRouter, useHistory } from "react-router-dom";

const Listings = () => {
  const card = () => {
    return (
      <div
        style={{
          border: "1px solid rgba(0, 0, 0, 0.125)",
          borderRadius: "0.25rem",
          marginBottom:'30px'
        }}
      >
        <div class="card">
          <div
            style={{
              position: "absolute",
              bottom: 10,
              color: "white",
              backgroundColor: "rgba(0,0,0,.5)",
              padding: "5px 10px 5px 10px",
              borderRadius: "50px",
              left: 10,
            }}
          >
            $3400
          </div>
          <img
            src="img/listings/list1.jpeg"
            class="card-img-top"
            alt="Card image cap"
          />
        </div>

        <div style={{ padding: "10px" }}>
          <p class="card-text">
            <span style={{ fontSize: 18,fontWeight:600 }}>11130 Sheldon Bend</span>
            <br />
            Richmond, TX, 77406.
          </p>
          <div style={{display:'flex',justifyContent:'space-between',alignContent:'center',alignItems:'center'}}>
          <div style={{display:'flex',alignItems:'center'}}>
          <FaBath style={{marginRight:'5px'}}/>3 <FaBed style={{marginLeft:'20px',marginRight:'5px',fontSize:18}}/> 4<FaCar style={{marginLeft:'20px',marginRight:'5px'}}/> 1
          </div>
          <Link to="/singleproperty" class="btn " style={{backgroundColor:'#C1852D',color:'white' }}>
        
            View Details
          
          </Link>
          </div>
        </div>
      </div>
    );
  };
  return (
    <section>
      <section class="intro-single">
        <div class="container">
          <div class="row">
            <div class="col-md-12 col-lg-8">
              <div class="title-single-box">
                <h1 class="title-single">Listings</h1>
              </div>
            </div>
            <div class="col-md-12 col-lg-4">
              <nav
                aria-label="breadcrumb"
                class="breadcrumb-box d-flex justify-content-lg-end"
              >
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    Listings
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </section>
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-lg-4">{card()}</div>
          <div className="col-md-6 col-lg-4">{card()}</div>
          <div className="col-md-6 col-lg-4">{card()}</div>
          <div className="col-md-6 col-lg-4">{card()}</div>
        </div>
      </div>
    </section>
  );
};

export default Listings;
