const SingleProperty = () =>{
    return(
        <div>
            <section class="intro-single">
    <div class="container">
      <div class="row">
        <div class="col-md-12 col-lg-8">
          <div class="title-single-box">
            <h1 class="title-single">11130 Sheldon Bend</h1>
            <span class="color-text-a">Richmond, TX, 77406.</span>
          </div>
        </div>
        <div class="col-md-12 col-lg-4">
          <nav aria-label="breadcrumb" class="breadcrumb-box d-flex justify-content-lg-end">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="index.html">Home</a>
              </li>
              <li class="breadcrumb-item">
                <a href="property-grid.html">Properties</a>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
              11130 Sheldon Bend
              </li>
            </ol>
          </nav>
        </div>
      </div>
    </div>
  </section>

  <section class="property-single nav-arrow-b">
    <div class="container">
      <div class="row">
        <div class="col-sm-12">
          <div  class="owl-arrow gallery-property">
            <div class="carousel-item-b">
              <img src="img/listings/list1.jpeg" alt="" style={{width:'100%'}} className="img-d img-fluid"/>
            </div>
            
          </div>
          <div class="row justify-content-between">
            <div class="col-md-5 col-lg-4">
          
              <div class="property-summary">
              <h5 class="title-c mb-5">$549,000</h5>
                <div class="row">
               
                  <div class="col-sm-12">
                  
                    <div class="title-box-d ">
                   
                      <h3 class="title-d">Quick Summary</h3>
                      
                    </div>
                  </div>
                </div>
                <div class="summary-list">
                  <ul class="list">
                    <li class="d-flex justify-content-between">
                      <strong>MLS ID:</strong>
                      <span>28029247</span>
                    </li>
                    <li class="d-flex justify-content-between">
                      <strong>Subdivision:</strong>
                      <span>Dry Creek Village</span>
                    </li>
                    <li class="d-flex justify-content-between">
                      <strong>Property Type:</strong>
                      <span>Single-Family</span>
                    </li>
                    <li class="d-flex justify-content-between">
                      <strong>Bedrooms:</strong>
                      <span>5 Bedroom(s)</span>
                    </li>
                    <li class="d-flex justify-content-between">
                      <strong>Baths:</strong>
                      <span>3 1
                        
                      </span>
                    </li>
                    <li class="d-flex justify-content-between">
                      <strong>Garage:</strong>
                      <span>2 Car Attached</span>
                    </li>
                    <li class="d-flex justify-content-between">
                      <strong>Stories:</strong>
                      <span>2</span>
                    </li>
                    <li class="d-flex justify-content-between">
                      <strong>Status:</strong>
                      <span>Active</span>
                    </li>

                    <li class="d-flex justify-content-between">
                      <strong>Year Built:</strong>
                      <span>2021</span>
                    </li>
                    <li class="d-flex justify-content-between">
                      <strong>Bldg SqFt:</strong>
                      <span>3,034 Appraisal District</span>
                    </li>
                    <li class="d-flex justify-content-between">
                      <strong>Lot Size:</strong>
                      <span>N/A</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-md-7 col-lg-7 section-md-t3">
              <div class="row">
                <div class="col-sm-12">
                  <div class="title-box-d">
                    <h3 class="title-d">Property Description</h3>
                  </div>
                </div>
              </div>
              <div class="property-description">
                <p class="description color-text-b">
                MUST SEE Gorgeous 4br/3.5ba/3 car garage in the 24/7 guarded/gated community of Westheimer Lakes. This beautiful home boasts detailed crown molding, soaring ceilings, spacious kitchen opens to a lovely family room, oversized 1st floor primary suite with separate tub and shower. 2nd floor features 3br/2ba and an oversized game room and media room. Relax and entertain in your own private pool with cooling deck. Brand NEW water heater and A/C units. Community amenities include tennis/basketball courts, walking trails, park and MUCH MORE! Come home to your own little piece of paradise!
                </p>
                
              </div>
              <div class="row section-t3">
                <div class="col-sm-12">
                  <div class="title-box-d">
                    <h3 class="title-d">Amenities</h3>
                  </div>
                </div>
              </div>
              <div class="amenities-list color-text-b">
                <ul class="list-a no-margin">
                  <li>Balcony</li>
                  <li>Outdoor Kitchen</li>
                  <li>Cable Tv</li>
                  <li>Deck</li>
                  <li>Tennis Courts</li>
                  <li>Internet</li>
                  <li>Parking</li>
                  <li>Sun Room</li>
                  <li>Concrete Flooring</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-10 offset-md-1">
          <ul class="nav nav-pills-a nav-pills mb-3 section-t3" id="pills-tab" role="tablist">
            <li class="nav-item">
              <a class="nav-link active" id="pills-video-tab" data-toggle="pill" href="#pills-video" role="tab"
                aria-controls="pills-video" aria-selected="true">Video</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" id="pills-plans-tab" data-toggle="pill" href="#pills-plans" role="tab" aria-controls="pills-plans"
                aria-selected="false">Floor Plans</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" id="pills-map-tab" data-toggle="pill" href="#pills-map" role="tab" aria-controls="pills-map"
                aria-selected="false">Ubication</a>
            </li>
          </ul>
        </div>
        </div>
        </div>
        </section>
        </div>
    )
}

export default SingleProperty;