import { Link, withRouter, useHistory, useLocation } from "react-router-dom";

const Nav=()=>{
    
    const location = useLocation();
    // useEffect(()=>{
    //     console.log("path===",location?.pathname)
    // },[location])

    const links=[
        {
            path:'/',
            name:'Home'

        },
        {
            path:'/agents',
            name:'Our Team'

        },
        {
            path:'/aboutus',
            name:'Company'

        },
        {
            path:'https://www.har.com/jobin-priyan-group/team_844',
            name:'Listings'

        },
        {
            path:'/contactus',
            name:'Contact'

        },
    ]
    return(
        <nav className="navbar navbar-default navbar-trans navbar-expand-lg fixed-top">
        <div className="container">
          <button
            className="navbar-toggler collapsed"
            type="button"
            data-toggle="collapse"
            data-target="#navbarDefault"
            aria-controls="navbarDefault"
            aria-expanded="false"
            aria-label="Toggle navigation"
            style={{color:'white'}}
          >
            <span style={{color:'white'}}></span>
            <span></span>
            <span></span>
          </button>
         
         
            <img src="img/jpgroup1.png" alt="img" height="70px"/>
        

      <div  class="btn btn-link nav-search navbar-toggle-box-collapse d-md-none" data-toggle="collapse"
        data-target="#navbarTogglerDemo01" aria-expanded="false">
         <img src="img/stride1.png" alt="img" height="55px"/>
      </div>
          <div
            class="navbar-collapse collapse justify-content-center" id="navbarDefault"
          >
            <ul className="navbar-nav">
           
           {links?.map((item,index)=>{
             if(item?.name==="Listings"){
               return <a href={item?.path} className="nav-item nav-link">{item?.name}  </a>
             } else {
              return(
                <Link to={item?.path} key={index}>
              <li className={`nav-item nav-link ${item?.path === location?.pathname ? 'active':''}`}>
                  {item?.name}  
              </li>
              </Link>
               )
             }
               
           })}
            
            </ul>
          </div>
          <div class="btn navbar-toggle-box-collapse d-none d-md-block" data-toggle="collapse"
        data-target="#navbarTogglerDemo01" aria-expanded="false">
       <img src="img/stride1.png" alt="img" height="55px"/>
      </div>
          
        </div>
      </nav>
    )
}

export default Nav;